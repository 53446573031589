<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
    :showRating="false"
  >
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import AssessmentType from "@/enums/assessmentType";
import assessmentMixin from "@/mixins/assessmentMixin";

export default {
  components: { AssessmentForm },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.Activities,
      form: this.getClearFormObject()
    };
  },

  computed: {},

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: this.residentId,
        assessmentDate: new Date(),
        gender: null,
        age: null
      };
    }
  }
};
</script>
